// Theme SCSS
@import "pages/page-post";
@import "pages/page-category";
@import "cards/card-post";

body.saveforlater {
	#wrapper {
		.section.home-splash {
			background:url("/assets/images/logo-splash.png");
			background-size:615px 419px;
			background-repeat: repeat;
			padding-top: 70px;
			padding-bottom: 0;
			margin-top: 0;
			border-top: 0.5px solid var(--color-base-bg-3);
			border-bottom: 5px solid var(--color-base-bg-3);
		}
	}
}
body.page-home {
	#wrapper {
		.section.home-splash {
			padding-top: 50px;
			padding-bottom: 0;
			margin-top: 0;
		}
	
		.section.home-posts {
			padding-top:60px;
			padding-bottom:60px;
		}
		.title {
			 .title-heading h1 {
				 font-size:56px;
		         @include media-breakpoint-up(md) {
		           font-size: 128px;
		         }
			 }
			 background-color: var(--color-base-bg-alpha);
			 .container {
				 padding-top:20px;
				 padding-bottom:20px;
				 
			 }
		}
		.title-description {
			margin-top:20px;
			width:100%;
			p {
				color:var(--color-primary);
			}
		}
		.title-extra {
			padding-top: 30px;
			padding-bottom: 0;
	        color: var(--color-base-text-2);
			font-size:21px;
			
		}
		.title-extra-credit {
			text-align:right;
	        color: var(--color-base-text-2);
			opacity:0.75;
	        font-size: 14px;
			
		}
		figcaption {
			text-align:right;
	        color: var(--color-base-text-2);
			opacity:0.75;
	        font-size: 14px;
			margin-top:4px;
		}
		.title-image {
			margin-top:20px;
		 	img {
				border:1px solid var(--color-base-bg-3);	
			}	
		}
	}
	
}

body.page-blog {
	#wrapper {
		.section.splash {
			background:url("/assets/images/logo-splash.png");
			background-size:615px 419px;
			background-repeat: repeat-x;
			padding-top: 70px;
			padding-bottom: 0;
			margin-top: 0;
			border-top: 0.5px solid var(--color-base-bg-3);
			border-bottom: 5px solid var(--color-base-bg-3);
			height:1px;
		}
	}
}

body.page-syllabus {
	#syllabus-menu {
		background:var(--color-base-bg-3);		
		.menu-syllabus {
			ul {
			    align-items: end;
				justify-content: flex-end;
			}
		}
	}
	ul li p + p {
		margin-top:-20px;
	}
}
body {
	.header {
		background:#EC255A url("/assets/images/logo-faded-splash.png");
		background-size:615px 419px;
		background-position-y:-25px;
		background-repeat: repeat;
		color:#fff;
	}
	.logos .logo .logo-text  {
		color:#fff;
		a { color: #fff; }
	}
	figure {
		margin-left:0; margin-right:0;
		img { width:100%;}
	}
	.section.page-top {
		padding-top: 70px;
		padding-bottom: 70px;
		margin-top: 0;
	}
	.section.post-top {
		padding-top: 90px;
		padding-bottom: 20px;
	}
	.specialcallout {
		border:2px solid var(--color-primary);	
		background:var(--color-base-bg);
		padding:1rem;
		text-align:center;
	}
}
