.card-post {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  .card-thumbnail {
    border-radius: $border-radius;
    overflow: hidden;
    display: inline-flex;
    a {
      display: inline-flex;
    }
    img {
      //aspect-ratio: 16 / 9;
      width: 100%;
      object-fit: contain;
      &:hover {
        opacity: 0.8;
      }
    }
    &.card-thumbnail-large {
      @include media-breakpoint-up(md) {
        max-height: 260px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 300px;
      }
    }
  }
  .card-content {
    padding-top: 30px;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .card-title {
    margin-bottom: 10px;
    h3 {
      font-weight: 500;
      line-height: 1.4;
      font-size: 21px;
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-description {
    margin: 10px 0 10px 0;
    p {
      line-height: 1.46;
      color: var(--color-base-text-2);
      margin: 0;
      font-size: 15px;
      font-weight: 300;
      overflow-y: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
  .card-title + .card-description {
    margin-top: 0;
  }
  .card-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-base-text-2);
    font-weight: 300;
    font-size: 13px;
    line-height: 1.3;
  }
  .card-categories {
    display: inline-flex;
    font-size: 12px;
    font-family: $font-family-base;
    a {
      color: var(--color-primary);
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      background-color: var(--color-base-bg-3);
    }
  }
  .card-image {
    width: 60px;
    margin-bottom: 10px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .card-meta {
    display: flex;
    margin-bottom: 10px;
    .on {
      margin: 0 4px 0 4px;
    }
  }
  .card-authors {

  }
  .card-readmore {
    margin-top: auto;
    line-height: 1;
    i {
      margin-left: 5px;
      margin-top: 4px;
    }
  }
  // Tweaks for dark mode
  html[data-mode="dark"] & {
  }
}
.card-post.card-row {
  border-radius: 0;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .card-thumbnail {
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0;
      margin-bottom: 10px;
      width: 340px;
      margin-right: 30px;
      margin-bottom: 0;
      a {
        background-color: var(--color-base-2);
      }
    }
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
.card-post.card-list {
  border-radius: 0;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .card-thumbnail {
    padding: 0;
    margin-bottom: 10px;
    margin-right: 16px;
    margin-bottom: 0;
    width: 80px;
    img {
      height: auto;
      object-fit: cover;
    }
  }
  .card-meta {
    margin-bottom: 2px;
  }
  .card-title {
    margin-bottom: 4px;
    font-weight: 600;
    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.3;
      margin: 0;
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  .card-authors {
    display: none;
  }
  .card-description {
    display: none;
  }
  .card-categories {
    display: none;
  }
}
.card-post.card-full {
  .card-thumbnail {
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0;
      margin-bottom: 10px;
      width: auto;
      max-height: none;
      margin-right: 0;
      margin-bottom: 30px;
      a {
        background-color: var(--color-base-200);
      }
    }
  }
  .card-content {

  }
  .card-categories {
    margin-top: 20px;
  }
  .card-meta {
    margin-top: 20px;
    order: 4;
  }
  .card-title {
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.4;
    flex-basis: 50%;
    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-description {
    flex-basis: 40%;
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
